import "unfetch/polyfill";
import axios from "axios";

export async function getFeatures(apiUrl, apiKey) {
  const { data } = await axios.get(`${apiUrl}featureGroup`, {
    params: { apiKey }
  });
  return data;
}

export async function getCategories(apiUrl, apiKey) {
  const { data } = await axios.get(`${apiUrl}categoryGroup`, {
    params: { apiKey }
  });
  return data;
}

export async function getCities(apiUrl, apiKey) {
  const { data } = await axios.get(`${apiUrl}cities`, {
    params: { apiKey }
  });
  return data;
}

export async function getConfig(apiUrl, apiKey) {
  const { data } = await axios.get(`${apiUrl}config`, { params: { apiKey } });
  return data;
}

export async function getJsonLd(apiUrl, apiKey, baseUrl, id) {
  const { data } = await axios.get(`${apiUrl}ld-json/${id}`, {
    params: { apiKey, base_url: encodeURIComponent(baseUrl) }
  });
  return data;
}

export async function getPois(apiUrl, apiKey, config = true, openFilter = "") {
  const { data } = await axios.get(`${apiUrl}poi`, {
    params: {
      ...getQuery(apiKey, config)
    }
  });

  if (openFilter === "open" && data?.data) {
    data.data = data.data.filter(
      poi =>
        poi?.state?.state === "open" ||
        (poi?.state?.state === "openingHours" && poi?.isOpen)
    );
  } else if (openFilter === "closed" && data?.data) {
    data.data = data.data.filter(
      poi =>
        poi?.state?.state === "closed" ||
        (poi?.state?.state === "openingHours" && !poi?.isOpen)
    );
  }
  if (config.selectedMappingField) {
    data.mappingField = config.selectedMappingField;
  }
  return data;
}

export async function getSummary(apiUrl, apiKey, config = true) {
  const { data } = await axios.get(`${apiUrl}summary`, {
    params: {
      ...getQuery(apiKey, config)
    }
  });
  return data;
}

export async function getMainPoi(apiUrl, apiKey, regionId) {
  const { data } = await axios.get(`${apiUrl}region/mainPoi`, {
    params: {
      _id: regionId,
      apiKey
    }
  });
  return data;
}

export async function getRegionSubPoisOpen(apiUrl, apiKey, query) {
  const mainPoiValues = query?.mainPoi;
  const mainPoiArr = Array.isArray(mainPoiValues)
    ? mainPoiValues
    : [mainPoiValues];

  const batchSize = 20;

  const mainPoiBatches = [];
  for (let i = 0; i < mainPoiArr.length; i += batchSize) {
    mainPoiBatches.push(mainPoiArr.slice(i, i + batchSize));
  }

  const results = [];
  for (const mainPoiBatch of mainPoiBatches) {
    const { data } = await axios.get(`${apiUrl}region/subPoisOpen`, {
      params: {
        filter: {
          mainPoi: { $in: mainPoiBatch }
        },
        apiKey
      }
    });
    results.push(data);
  }

  return results.flat();
}

function getQuery(apiKey, config) {
  let {
    _id,
    containByCategoryGroupIds,
    containByCategoryTypeIds,
    difficulty,
    category_ids,
    containByCategoryIds,
    highlight_to_top,
    s,
    slug,
    location,
    extended,
    offset,
    page,
    limit,
    selectedMappingField,
    sort,
    direction,
    containByFeatureIds,
    feature_ids,
    active,
    highlight,
    hidden,
    unset,
    excludePois,
    cities
  } = config ?? {};
  const query = { apiKey };
  if (_id) {
    query["_id[$in]"] = _id;
  }

  feature_ids = feature_ids?.filter(ft => ft.length > 1);
  category_ids = category_ids?.filter(ct => ct.length > 1);

  // fixed containments - categories?
  if (containByCategoryGroupIds) {
    query["categoryGroup[$in]"] = containByCategoryGroupIds;
  }
  if (containByCategoryTypeIds) {
    query["categoryType[$in]"] = containByCategoryTypeIds;
  }
  if (containByCategoryIds) {
    // either category or additionalCategories must match
    query["$or[0][category][$in]"] = containByCategoryIds;
    query["$or[1][additionalCategories][$in]"] = containByCategoryIds;
  }
  if (difficulty) {
    query.difficulty = difficulty;
  }

  // category selected?
  if (category_ids && category_ids.length) {
    // either category or additionalCategories must match
    if (query["$or[0][category][$in]"]) {
      query["$and[0]$or[0][category][$in]"] = query["$or[0][category][$in]"];
      query["$and[0]$or[1][additionalCategories][$in]"] =
        query["$or[1][additionalCategories][$in]"];
      query["$and[1]$or[0][category][$in]"] = category_ids;
      query["$and[1]$or[1][additionalCategories][$in]"] = category_ids;

      delete query["$or[0][category][$in]"];
      delete query["$or[1][additionalCategories][$in]"];
    } else {
      query["$or[0][category][$in]"] = category_ids;
      query["$or[1][additionalCategories][$in]"] = category_ids;
    }
  }

  // fixed containments - features?
  if (containByFeatureIds) query["features[$in]"] = containByFeatureIds;

  // features selected?
  if (feature_ids && feature_ids.length) {
    query["features[$all]"] = feature_ids;
  }

  if (cities?.length) {
    query["cities[$in]"] = cities;
  }

  // active state
  if (active) {
    query.active = true;
  }

  // hidden state
  if (!isNaN(hidden)) {
    const p = parseInt(hidden);
    if (p === 1 || p === 0) query.hidden = !!p;
  }

  // highlights only
  if (highlight) {
    query.highlight = true;
  }

  if (highlight_to_top) {
    query["$sort[highlight]"] = -1;
  }

  if (s) query.$search = s;

  if (slug) query.slug = slug;

  if (location) query["address.city"] = location;

  if (extended) query.extended = 1;

  let skip = offset || 0;
  if (page) {
    skip = (page - 1) * limit;
  }

  if (limit) query.$limit = limit;

  if (selectedMappingField) query.selectedMappingField = selectedMappingField;

  if (skip) query.$skip = skip;

  //exclude POIs
  if (query.$skip) query.excludePois = excludePois;

  if (sort) {
    query["$sort[" + encodeURIComponent(sort) + "]"] =
      direction === "desc" ? -1 : 1;
  }

  if (unset) {
    query.unset = unset;
  }
  return query;
}
